<template>
  <Component :is="type" class="info" :class="{error,grey,blue,formError}">
    <slot />
  </Component>
</template>

<script lang="ts" setup>
const props = defineProps({
  list: {
    type: Boolean
  },
  error: {
    type: Boolean
  },
  grey: {
    type: Boolean
  },
  blue: {
    type: Boolean
  },
  formError: {
    type: Boolean
  }
})
const type = computed(() => {
  return props.list ? 'ul' : 'div'
})
</script>

<style lang="postcss" scoped>
.info{
  @apply bg-[#d8f3ef] list-image-[url(/img/list-style-img.svg)] list-outside p-2 rounded border border-green text-gray-700 text-[16px];
  :deep(p){
    @apply leading-[1.5];
  }
  :deep(li){
    @apply mb-0.5 ml-2;
  }
  &.error {
    @apply border-red bg-[#fcdbe4];
  }
  &.grey {
    @apply border-gray-200 bg-gray-50;
  }
  &.blue{
    @apply border-none bg-[#e7eff9];
  }
  &.formError{
    @apply border-red bg-[#fcdbe4] p-1 py-0.5 text-sm text-red;
  }
}
</style>
